import clsx from 'clsx'
import React from 'react'
import * as styles from './partners.module.scss'
import Slider, { Settings } from 'react-slick'
import { FcNext, FcPrevious } from 'react-icons/fc'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Button } from '../../components/button'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import unimed from '../../assets/testimonials/unimed.png'
import p4Engenharia from '../../assets/testimonials/p4engenharia.png'
import sicoob from '../../assets/testimonials/sicoob.png'
import supersonic from '../../assets/testimonials/supersonic.png'
import usinaSantaAdelia from '../../assets/testimonials/usinaSantaAdelia.png'
import universal from '../../assets/testimonials/universal.png'
import premix from '../../assets/testimonials/premix.png'
import medilar from '../../assets/testimonials/medilar.png'
import simplea from '../../assets/testimonials/simplea.png'
import iliberty from '../../assets/testimonials/iliberty.png'
import nexum from '../../assets/testimonials/nexum.png'
import join4 from '../../assets/testimonials/join4.png'
import zixbe from '../../assets/testimonials/zixbe.png'
import digiSystem from '../../assets/testimonials/digiSystem.png'
import i18n from '../../i18n'

interface TestimonialType {
  img: string
  title: string
  alt: string
}

const customers: TestimonialType[] = [
  {
    img: sicoob,
    title: 'Sicoob',
    alt: 'Sicoob',
  },
  {
    img: usinaSantaAdelia,
    title: 'Usina Santa Adélia',
    alt: 'Usina Santa Adélia',
  },
  {
    img: unimed,
    title: 'Unimed',
    alt: 'Unimed',
  },
  {
    img: universal,
    title: 'Universal',
    alt: 'Universal',
  },
  {
    img: premix,
    title: 'Premix',
    alt: 'Premix',
  },
  {
    img: medilar,
    title: 'Medilar',
    alt: 'Medilar',
  },
  {
    img: p4Engenharia,
    title: 'P4 Engenharia',
    alt: 'P4 Engenharia',
  },
  {
    img: supersonic,
    title: 'Supersonic',
    alt: 'Supersonic',
  },
]

const partners: TestimonialType[] = [
  {
    img: simplea,
    title: 'Simplea',
    alt: 'Simplea',
  },
  {
    img: iliberty,
    title: 'iLiberty',
    alt: 'iLiberty',
  },
  {
    img: nexum,
    title: 'Nexum',
    alt: 'Nexum',
  },
  {
    img: join4,
    title: 'Join4',
    alt: 'Join4',
  },
  {
    img: zixbe,
    title: 'consisa',
    alt: 'consisa',
  },
  {
    img: digiSystem,
    title: 'DigiSystem',
    alt: 'DigiSystem',
  },
]

export const PartnerList: React.FC<PartnersProps> = (props) => {
  const list = React.useMemo(() => {
    if (props.type === 'partners') return partners
    return customers
  }, [props.type])

  const sliderSettings: Settings = {
    slidesToShow: props?.slidesToShow || 6,
    slidesToScroll: props?.slidesToScroll || 6,
    infinite: true,
    arrows: true,
    initialSlide: 0,
    nextArrow: <FcNext />,
    prevArrow: <FcPrevious />,
    autoplay: props.isAutoplay,
    autoplaySpeed: 6000,
    responsive: [
      {
        // Mobile
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        // Tablet
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
  }

  if (props.onlySlider) {
    return (
      <div className={clsx(styles.partners)}>
        <Slider {...sliderSettings}>
          {list.map((item, key) => (
            <div key={key}>
              <div
                className={clsx({
                  [styles.cardPartners]: true,
                })}
              >
                <img src={item.img} alt={item.alt} title={item.title} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }

  return (
    <div className={clsx(styles.partners)}>
      <div className={clsx('container', styles.partners)}>
        <div
          className={clsx({
            [styles.title]: true,
          })}
        >
          <h1>{i18n[`partnerList.partner`]}</h1>
          <p>{i18n[`partnerList.partnerText`]}</p>
        </div>
        <Slider {...sliderSettings}>
          {list.map((item, key) => (
            <div key={key}>
              <div
                className={clsx({
                  [styles.cardPartners]: true,
                })}
              >
                <img src={item.img} alt={item.alt} title={item.title} />
              </div>
            </div>
          ))}
        </Slider>
        <div className={styles.contact}>
          <AnchorLink to={`#BecomePartnerForm`}>
            <Button color="darkBlue" size="large">
              {i18n[`common.beRobertyPartner`]}
            </Button>
          </AnchorLink>
        </div>
      </div>
    </div>
  )
}

PartnerList.defaultProps = {
  type: 'partners',
  isAutoplay: false,
  onlySlider: false,
  slidesToScroll: 6,
  slidesToShow: 6,
}

type PartnersProps = {
  type?: 'customers' | 'partners'
  onlySlider?: boolean
  slidesToShow?: number
  slidesToScroll?: number
  isAutoplay?: boolean
}
